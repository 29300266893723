.square-loader {
    display: inline-block;
    height: 100%;
    position: relative;
    width: 100%;
  }
  
  .square-loader div {
    animation: square-loader 1.2s linear infinite;
    background: #fff;
    border-radius: 50%;
    height: 30%;
    position: absolute;
    width: 30%;
  }
  
  .square-loader div:nth-child(1) {
    animation-delay: 0s;
    background-color: #1ea6e6;
    left: 0;
    top: 0;
  }
  
  .square-loader div:nth-child(2) {
    animation-delay: -0.4s;
    background-color: #f15029;
    left: 33%;
    top: 0;
  }
  
  .square-loader div:nth-child(3) {
    animation-delay: -0.8s;
    background-color: #b5dc17;
    left: 66%;
    top: 0;
  }
    
  .square-loader div:nth-child(4) {
    animation-delay: -0.4s;
    background-color: #f15029;
    left: 0;
    top: 33%;
  }
  
  .square-loader div:nth-child(5) {
    animation-delay: -0.8s;
    background-color: #b5dc17;
    left: 33%;
    top: 33%;
  }
    
  .square-loader div:nth-child(6) {
    animation-delay: -1.2s;
    background-color: #1ea6e6;
    left: 66%;
    top: 33%;
  }
  
  .square-loader div:nth-child(7) {
    animation-delay: -0.8s;
    background-color: #b5dc17;
    left: 0;
    top: 66%;
  }
    
  .square-loader div:nth-child(8) {
    animation-delay: -1.2s;
    background-color: #1ea6e6;
    left: 33%;
    top: 66%;
  }
  
  .square-loader div:nth-child(9) {
    animation-delay: -1.6s;
    background-color: #f15029;
    left: 66%;
    top: 66%;
  }
    
  @keyframes square-loader {
    0%, 100% {
      opacity: 1;
    }
  
    50% {
      opacity: 0.5;
    }
  }