@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .page-header-icon {
    @apply h-28 w-auto text-white opacity-60
  }
}

table:focus-visible {
  border: none;
  outline: none;
}

.rdp {
  margin: 0 !important;
}

.rdp-day_selected {
  background-color: #7828c8 !important;
}

.rc-time-picker-panel {
  max-width: none !important;
  border: none !important;
  width: 200px !important;
}

.rc-time-picker-panel-select-option-selected {
  background-color: #e3e0e0 !important;
}

.rc-time-picker-panel-select li:hover {
  background-color: #e3e0e0 !important;
}

.rc-time-picker-panel-inner {
  background-color: #f4f4f5 !important;
  border: none !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  width: 212px !important;
  margin-top: 4px !important;
}

.rc-time-picker-panel-select {
  width: 50% !important;
}

.rc-time-picker-panel-input-wrap {
  border-bottom-color: #dadada !important;
  background-color: #f4f4f5 !important;
  border-radius: 10px 10px 0px 0px !important;
}

.rc-time-picker-panel-input {
  background-color: #f4f4f5 !important;
  font-size: 0.875rem !important;
  /* color: white !important; */
  font-weight: 600 !important;
  text-align: center !important;
}

.rc-time-picker-input {
  background-color: #f4f4f5 !important;
  border: none !important;
  padding: 20px !important;
  border-radius: 8px !important;
  font-size: 0.875rem !important;
  width: 200px;
}

.rc-time-picker-input:focus-visible {
  outline: none !important;
}

.rc-time-picker-panel-select li {
  font-size: 14px !important;
}

/**



**/