.lineloader-spinner {
    margin: 0rem auto 0;
    text-align: center;
    width: 100px;
  }
  
  .lineloader-spinner > div {
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    height: 18px;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    width: 18px;
  }
  
  .lineloader-spinner .bounce1 {
    animation-delay: -0.32s;
    background-color: #1ea6e6;
    margin: 0 4px;
    -webkit-animation-delay: -0.32s;
  }
  
  .lineloader-spinner .bounce2 {
    animation-delay: -0.16s;
    background-color: #f15029;
    margin: 0 4px;
    -webkit-animation-delay: -0.16s;
  }
  
  .lineloader-spinner .bounce3 {
    background-color: #b5dc17;
    margin: 0 4px;
  }
  
  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bouncedelay {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }